import { Injectable, inject } from '@angular/core';
import { apiUrls } from '../../../../environments/environments';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);
  baseUrl = apiUrls.auth;

  get token(): string | undefined {
    return localStorage.getItem('@auth:token') ?? undefined;
  }

  set token(value: string | undefined) {
    if (!value) localStorage.removeItem('@auth:token');
    if (!!value) localStorage.setItem('@auth:token', value);
  }

  async login(email: string, password: string): Promise<string> {
    const url = this.baseUrl + 'login';
    const body = { email, password };

    const observable = this.http.post<{ token: string }>(url, body);
    const response = await firstValueFrom(observable);
    return response.token;
  }

  async loginWithTwillo(
    email: string,
    password: string,
    userNumber: string,
  ): Promise<string> {
    const url = this.baseUrl + 'login';
    const params = { twilioRequest: 'True', userNumber };
    const body = { email, password };

    const observable = this.http.post<{ token: string }>(url, body, { params });
    const response = await firstValueFrom(observable);
    return response.token;
  }
}
